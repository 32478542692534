import { alpha, createTheme } from '@mui/material/styles';

const typography = {
  fontFamily: ['Inter', 'Arial', 'sans-serif'].join(','),
};

export const paletteDark = {
  mode: 'dark',
  primary: {
    main: '#005b50',
  },
  lavender: {
    l10: '#f4efff',
    l20: '#ece2ff',
    l50: '#e3d6ff',
    l100: '#d6c8fe',
    l200: '#af7fe7',
    l300: '#8e5cbb',
    l500: '#724E93',
    l700: '#5F4180',
    l700dim: alpha('#5F4180', 0.6),
    l500dim: alpha('#724E93', 0.5),
  },
  menthol: {
    m20: '#003d3699',
    m50: '#cbebec',
    m100: '#C6E6E7',
    m400: '#4f867c',
    m500: '#2e756d',
    m600: '#005b50',
    m700: '#004d43',
    m800: '#003834',
  },
  secondary: {
    main: '#b6b6b6',
  },
  text: {
    primary: '#f6f6f6',
    secondary: '#b6b6b6',
  },
  neutral: {
    n10: '#fbfbfb',
    n20: '#f6f6f6',
    n50: '#cecece',
    n100: '#919191',
    n200: '#838383',
    n400: '#696969',
    n600: '#4b4b4b',
    n700: '#3f3f3f',
    n800: '#393939',
    n900: '#363636',
  },
  blue: {
    b10: '#e3eeff',
    b50: '#BBE1FA',
    b100: '#3282B8',
    b200: '#0F4C75',
  },
  semantic: {
    error: '#DB4D4D',
    warning: '#F0B323',
    success: '#20d382',
    info: '#5E41C6',
    warningDim: '#a87f00',
    errorDim: '#7c2929',
  },
  common: {
    white: '#fff',
    black: '#000',
  },
  background: {
    default: '#404040',
    paper: '#404040',
  },
};

export const paletteLight = {
  mode: 'light',
  primary: {
    main: '#005b50',
  },
  secondary: {
    main: '#393939',
  },
  lavender: {
    l10: '#3c2952',
    l20: '#452f60',
    l50: '#573b7a',
    l100: '#7c58a4',
    l200: '#af7fe7',
    l300: '#d6c8fe',
    l500: '#e3d6ff',
    l700: '#ece2ff',
    l700dim: alpha('#ece2ff', 0.6),
    l500dim: alpha('#e3d6ff', 0.5),
  },
  menthol: {
    m100: '#003d36',
    m400: '#018675',
    m500: '#47948b',
    m600: '#53a69e',
    m700: '#7eb9b0',
    m800: '#003d3699',
  },
  text: {
    primary: '#1f1f1f',
    secondary: '#333333',
  },
  neutral: {
    n10: '#262626',
    n20: '#363636',
    n50: '#393939',
    n100: '#3f3f3f',
    n200: '#4b4b4b',
    n400: '#6e6e6e',
    n600: '#9b9b9b',
    n700: '#cecece',
    n800: '#f6f6f6',
    n900: '#fbfbfb',
  },
  blue: {
    b10: '#0F4C75',
    b50: '#3282B8',
    b100: '#BBE1FA',
    b200: '#e3eeff',
  },
  semantic: {
    error: '#f34646',
    warning: '#F0B323',
    success: '#5BB708',
    info: '#5E41C6',
    warningDim: '#D7A411',
    errorDim: '#da3e3e',
  },
  common: {
    white: '#fff',
    black: '#000',
  },
  background: {
    default: '#fafafa',
    paper: '#fafafa',
  },
};

export const NAVBAR_HEIGHT = 50;
export const FOOTER_HEIGHT = 30;
export const NAVBAR_Z_INDEX = 99;
/**
 * Colors palette to be used throughout project.
 * Could be accessed either in MUI theme scope or directly imported from here
 */
export function createThemeByMode(mode) {
  const palette = mode === 'light' ? paletteLight : paletteDark;

  return createTheme({
    palette,
    typography,
    breakpoints: {
      values: {
        xs: 0,
        sm: 520,
        md: 960,
        lg: 1280,
        l1: 1440,
        l2: 1680,
        xl: 1920,
      },
    },
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            minHeight: '32px',
            height: '32px',
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            overflowY: 'scroll',
            overflowX: 'hidden',
            scrollbarWidth: 'none' /* Firefox */,
            msOverflowStyle: 'none' /* Internet Explorer 10+ */,
            '&::-webkit-scrollbar': {
              width: 0,
              height: 0,
            },
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: palette.neutral.n700,
              color: '#fff',
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '8px',
          },
          head: {
            backgroundColor: palette.primary.main,
            color: '#fff',
            fontWeight: 900,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: '32px',
            height: '32px',
          },
        },
      },
      MuiTabPanel: {
        styleOverrides: {
          root: {
            padding: '12px 0',
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: { borderRadius: '2px' },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontSize: 14,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltipPlacementBottom: {
            margin: '8px 0',
            padding: '6px 8px',
            backgroundColor: palette.neutral.n400,
            fontSize: '0.75rem',
          },
        },
      },
      MuiOutlinedInput: {
        defaultProps: {
          notched: true,
        },
        styleOverrides: {
          root: { borderRadius: 4 },
          input: {
            '&::placeholder': { color: palette.neutral.n200, opacity: 0.7 },
          },
          multiline: {
            '&::placeholder': { color: palette.neutral.n200, opacity: 0.7 },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiAutocomplete: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiSelect: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiInputLabel: {
        defaultProps: {
          shrink: true,
        },
      },
      MuiButton: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiInput: {
        defaultProps: {
          disableUnderline: true,
        },
      },
      MuiModal: {
        defaultProps: {
          disableEnforceFocus: true,
        },
      },
    },
    navbarHeight: NAVBAR_HEIGHT,
    footerHeight: FOOTER_HEIGHT,
  });
}

export const palette = paletteDark;
